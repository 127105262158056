$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px );
.App {
  text-align: center;
}

.enderecoList .filterButton {
  height: 50px
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0288D1;
  background: radial-gradient(circle, #039BE5, #01579b);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  font-family: sans-serif;
}

.firebaseUi {
  min-width: 250px;
}

.signup {
  height: 30em;
  display: flex;
  align-items: center;
  justify-content: center
   
}

.signupChild {
  margin: 0;
  text-align: center;
  background-color: rgb(127, 240, 255);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Navbar {
  background-color: #3f51b5;
  //position: fixed;
  top: 0;
  left: auto;
  color: white;
  right: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  font-family: Roboto;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 0px 5px silver;
}

.Toolbar {
  display: flex;
  min-height: 56px;
  align-items: center;
  padding: 0px 10px;
}

.Logo {
  margin-right: 10px;
}

.Title {
  flex-grow: 3;
}


