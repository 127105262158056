.modal-dialog .modal-header {
    background-color: 	#0d6efd ;
} 

.modal-dialog .modal-title {
    color : white
} 

.filter {
    margin-top: 20px;
}

